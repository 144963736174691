import { useQuery } from "react-query";
import { useSessionContext } from "../../../session/store/sessionContext";
import { apiCall } from "../../../../shared/axios/axios-config";
import {
  FilterInterface,
  PrivateQueryDataTagFPInterface,
} from "../../../../shared/globals/utilsGlobalTypes";
import { PeriodInterface } from "../../../../shared/date/usePeriod";
import { getDateWithDayPlusOne } from "../../../../utils/getDateWithDayPlusOne";

// ----------------------------------------------------------------------------------------------------
// Interfaces

type ObservationReportQuery<T = any> = FilterInterface<T> & {
  firstDate?: string;
  secondDate?: string;
};

interface ObservationReport {
  sector: string;
  equipment: string;
  element: string;
  component: string;
  tagPlanta: string;
  date: string;
  observation: string;
  user: string;
  criticality: string;
  area: string;
}

// ----------------------------------------------------------------------------------------------------
// Services

export const GetObservationReportList = ({
  data,
  tagFP,
  token,
}: PrivateQueryDataTagFPInterface<ObservationReportQuery<ObservationReport>>) => {
  return apiCall({
    method: "POST",
    url: "/reports/observation-reports-list",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
    data,
  });
};

export const GetObservationReportMap = ({
  data,
  tagFP,
  token,
}: PrivateQueryDataTagFPInterface<ObservationReportQuery<ObservationReport>>) => {
  return apiCall({
    method: "POST",
    url: "/reports/observation-reports-map",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
    data,
  });
};

// ----------------------------------------------------------------------------------------------------
// Adapters

export const useGetObservationReportList = (
  period: PeriodInterface | undefined,
  {
    filters,
    searchValue,
  }: {
    filters: any;
    searchValue: string;
  }
) => {
  const { token, tagFP } = useSessionContext();
  const canFetch = [
    !!period?.firstDate,
    !!period?.secondDate,
    !!tagFP,
    !!token,
    token != "",
  ].includes(false)
    ? false
    : true;

  const data = {
    filters: {
      ...filters,
      startTime: [period?.firstDate, getDateWithDayPlusOne(period?.secondDate)],
    },
    searchValue: searchValue,
  };

  return useQuery({
    queryKey: ["GetObservationReportList", period, filters, searchValue],
    queryFn: () => GetObservationReportList({ data, tagFP, token }),
    enabled: canFetch,
  });
};

export const useGetObservationReportMap = (
  period: PeriodInterface | undefined,
  {
    filters,
    searchValue,
  }: {
    filters: any;
    searchValue: string;
  }
) => {
  const { token, tagFP } = useSessionContext();
  const canFetch = [
    !!period?.firstDate,
    !!period?.secondDate,
    !!tagFP,
    !!token,
    token != "",
  ].includes(false)
    ? false
    : true;

  const data = {
    filters: {
      ...filters,
      startTime: [period?.firstDate, getDateWithDayPlusOne(period?.secondDate)],
    },
    searchValue: searchValue,
  };

  return useQuery({
    queryKey: ["GetObservationReportMap", period, filters, searchValue],
    queryFn: () => GetObservationReportMap({ data, tagFP, token }),
    enabled: canFetch,
  });
};
