import React, { useState } from "react";

//Materia
import { Paper, Typography } from "@material-ui/core";
import FlexContainer from "../../../components/containers/FlexContainer";
import PageContainer from "../../../components/containers/PageContainer";
import SidebarContainer from "../../../components/containers/SidebarContainer";
import EntityList from "../../../components/grids/EntityList";
import OnlineOfflineMode from "../../../shared/config/offline Mode/OnlineOfflineMode";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useAdministators } from "../../person/services/personServices";
import { PersonInterface } from "../models/PersonInterface";
import AddPersonForm from "./AddPersonForm";

const AddAdminPageRefactor: React.FC = () => {
  const { lang } = useLangLabels();
  const {
    data: admins,
    query: { status: adminStatus },
  } = useAdministators();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_personData, setPersonData] = useState<any>();

  const handlePerson = (person: PersonInterface) => {
    setPersonData(person);
  };

  return (
    <OnlineOfflineMode>
      <SidebarContainer>
        <EntityList
          title={lang.persons.admins}
          icon={"person"}
          status={adminStatus}
          items={admins}
        />
        <Paper>
          <FlexContainer border={"4px 0px 0px solid red"} />
          <PageContainer padding="16px 16px 0px">
            <Typography component={"h6"}>{lang.persons.newAdministrator}</Typography>
          </PageContainer>
          <AddPersonForm role="Administrador" handlePerson={handlePerson} />
        </Paper>
      </SidebarContainer>
    </OnlineOfflineMode>
  );
};

export default AddAdminPageRefactor;
