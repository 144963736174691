import React from "react";

import { makeStyles, Paper, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import HomeHeroImg from "../../../assets/img/welcomeHeader.png";
import CustomButton from "../../../components/buttons/CustomButton";
import { usePerson, useUser } from "../../../domains/session/store/sessionStore";
import { USER_ROUTES } from "../../../shared/routes/routes";
import NotificationsPage from "../NotificationsPage";

const useStyles = makeStyles(theme => ({
  content: {
    display: "flex",
    gap: "24px",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: "24px",
    width: "auto",
    "& > *": {
      margin: theme.spacing(0),
      flex: "1",
      minWidth: theme.spacing(30),
      padding: theme.spacing(2),
    },
  },

  heroNavPlant: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(-10),
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(-10),
      marginBottom: theme.spacing(0),
    },
    "& > *": {
      width: "calc(100% - 48px)",
      marginTop: theme.spacing(-10),
      marginLeft: theme.spacing(6),
      marginBottom: theme.spacing(4),
      color: "#f3f3f3",
      textTransform: "uppercase",
      fontWeight: "900",
      letterSpacing: "1px",
    },
  },
  hero: {
    height: "20vh",
    width: "100%",
    backgroundPosition: "center",
  },
}));

const HomeContent: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.content}>{children}</div>;
};

const HomeHero: React.FC<{ title: string }> = ({ title }) => {
  const classes = useStyles();

  return (
    <>
      <Paper
        className={classes.hero}
        style={{ backgroundColor: "#f3f3f3", backgroundImage: `url(${HomeHeroImg})` }}
        elevation={4}
      />
      <div className={classes.heroNavPlant}>
        <Typography variant="h5" component="h5">
          {title}
        </Typography>
      </div>
      <br />
    </>
  );
};

const ButtonHomeNavigation: React.FC<{}> = ({}) => {
  const { data: user } = useUser();

  const navigate = useNavigate();
  return (
    <>
      {user?.type === "A" || user?.type === "AC" || user?.type === "SA" ? (
        <>
          {user?.type === "A" || user?.type === "SA" ? (
            <CustomButton
              icon="new-release"
              action={() => navigate(`${USER_ROUTES.addUser}/${USER_ROUTES.demoUsers}`)}
              title="Prospectos"
              color="primary"
            />
          ) : null}
        </>
      ) : null}
      <CustomButton
        icon="person"
        action={() => navigate(USER_ROUTES.addUser)}
        title="Usuarios"
        color="primary"
      />
      <div></div>
      <div></div>
    </>
  );
};

const HomePage: React.FC<{}> = ({}) => {
  const { data: person } = usePerson();

  return (
    <div>
      <HomeHero title={person?.role!} />
      <HomeContent>
        <ButtonHomeNavigation />
      </HomeContent>
      <NotificationsPage />
    </div>
  );
};

export default React.memo(HomePage);
