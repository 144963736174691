import { AxiosRequestConfig } from "axios";
import { httpRequest } from "../../../shared/axios/axios-config";
import { PrivateQueryTagFPInterface } from "../../../shared/globals/utilsGlobalTypes";
import { useQuery } from "../../../shared/react-query/react-query-conf";
import { useSessionContext } from "../../session/store/sessionContext";
import { AllAuxInterface } from "../model/allAuxInterface";

export const getAllAuxByPlant = ({ tagFP, token }: PrivateQueryTagFPInterface) => {
  const config: AxiosRequestConfig = {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP: `${tagFP}`,
    },
    url: `/AuxByTagFP`,
  };
  return httpRequest(config)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const useAllAuxByPlant = () => {
  const { tagFP, token } = useSessionContext();
  return useQuery<AllAuxInterface>({
    queryKey: "PlantAux",
    queryFn: () => getAllAuxByPlant({ tagFP, token }),
    enabled: !!token && !!tagFP,
  });
};

export const useGetAllAuxByPlantData = <K extends keyof AllAuxInterface>(selectBy: K) => {
  const { tagFP, token } = useSessionContext();
  return useQuery({
    queryKey: "PlantAux",
    queryFn: () => getAllAuxByPlant({ tagFP, token }),
    select: (data: AllAuxInterface) => {
      if (!!selectBy) {
        return data[selectBy];
      }
    },
    enabled: !!token && !!tagFP,
  });
};
