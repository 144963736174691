import React from "react";
import { Route, Routes } from "react-router-dom";
import { Plant } from "../../../../domains/plants/PlantInterface";
import Reports from "../../../../domains/powerBi/components/Reports";
import { CustomUserPbiData } from "../../../../domains/powerBi/models/powerBiInterfaces";
import User from "../../../../domains/users/UserInterface";
import ClientHome from "../../ClientVisor/home/ClientHome";

import UserDashboard from "./UserDashboard";

interface Props {
  user?: User;
  userRole: string;
  currentPbiGroupName: string;
  selectedPlant: Plant;
  baseUrl?: string;
}

const ClientContent: React.FC<Props> = ({ currentPbiGroupName, userRole }) => {
  const userType = "C";
  const customConfig: CustomUserPbiData = { defaultUser: userType, name: currentPbiGroupName };

  return (
    <>
      <UserDashboard
        {...{
          userType,
          currentPbiGroupName,
          userRole,
        }}
      >
        <Routes>
          <Route path="" element={<ClientHome defaultConfig={customConfig} />} />
          <Route path="*" element={<Reports />} />
        </Routes>
      </UserDashboard>
    </>
  );
};

export default ClientContent;
