import React from "react";

import { CircularProgress, useTheme } from "@material-ui/core";
import { PersonInterface } from "../person/models/PersonInterface";
import { useSessionContext } from "../session/store/sessionContext";
import OnlineOfflineMode from "../../shared/config/offline Mode/OnlineOfflineMode";
import { useAssignCancelUserPlant, useUserActivatedPlants } from "./plantsService";
import ResourceGrid from "../../components/grids/ResourceGrid";

interface Props {
  action: "assign" | "cancel";
  title: string;
  userData: PersonInterface | undefined | null;
  roleToEdit: string;
  children?: React.ReactNode;
}

const AssignCancelPlantsRefactor: React.FC<Props> = ({ action, title, userData, roleToEdit }) => {
  const theme = useTheme();
  const userEmail = userData?.email || null;
  const { plants, user } = useSessionContext();
  const { data: userActivePlants, isLoading } = useUserActivatedPlants(userEmail);

  const { handleUserPlants } = useAssignCancelUserPlant(userEmail);

  const handleCLick = (item: any) => {
    let data: any = {
      email: userEmail,
      tagFP: item.tagFP,
      role: roleToEdit,
      state: action.charAt(0).toUpperCase(),
      actualUserEmail: user?.email,
    };
    handleUserPlants(action, data);
  };

  if (action === "assign") {
    const filteredItems = () => {
      //filter array of items by items from an other array
      let tags: any[] = [];
      let results: any[] = [];
      if (userActivePlants) {
        userActivePlants.forEach((element: any) => {
          tags.push(element.tagFP);
        });
        if (userActivePlants) {
          results = plants.filter((item: any) => !tags.includes(item.tagFP));
        }
      }
      return results;
    };

    const handleDisable = () => {
      if (roleToEdit === "O" || roleToEdit === "S") {
        if (userActivePlants && userActivePlants.length > 0) {
          return true;
        }
        return false;
      }
      return false;
    };

    return (
      <OnlineOfflineMode>
        <ResourceGrid
          color={theme.palette.primary.main}
          disabled={handleDisable()}
          allResources={filteredItems()}
          title={title}
          action={handleCLick}
          onLoad={isLoading ? <CircularProgress /> : "Este usuario no tiene plantas para asignar"}
        />
      </OnlineOfflineMode>
    );
  }

  if (action === "cancel") {
    return (
      <OnlineOfflineMode>
        <ResourceGrid
          color={theme.palette.primary.main}
          allResources={userActivePlants}
          title={title}
          disabled={false}
          action={handleCLick}
          onLoad={isLoading ? <CircularProgress /> : "Este usuario no tiene plantas asignadas"}
        />
      </OnlineOfflineMode>
    );
  } else {
    return null;
  }
};
export default AssignCancelPlantsRefactor;
