import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SessionProvider } from "./domains/session/store/sessionContext";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./shared/react-query/react-query-conf";
import { ReactQueryDevtools } from "react-query/devtools";
import theme from "./assets/theme";
import { ThemeProvider } from "@material-ui/core";
import ErrorBoundaryPage from "./pages/ErrorBoundaryPage/ErrorBoundaryPage";
import { ErrorBoundary } from "./shared/errorBoundary/ErrorBoundary";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <SessionProvider>
            <ErrorBoundary fallBackComponent={<ErrorBoundaryPage />}>
              <App />
            </ErrorBoundary>
          </SessionProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
