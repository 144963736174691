import { useState } from "react";
import { useUser } from "../../domains/session/store/sessionStore";

import LZString from "lz-string";

import {
  BasicLocalStorageHookProps,
  CallBack,
  SetValue,
  UseBasicLocalStorage,
} from "./types/localStorageTypes";

const useBasicLocalStorage = <T extends any>({
  key,
  initialValue,
  storeLocal = true,
  storeWithEmail = true,
  getStoredValueMiddleware = value => value,
}: BasicLocalStorageHookProps<T>): UseBasicLocalStorage<T> => {
  const { data: user } = useUser();

  const lastUserString = localStorage.getItem("last-user");
  const lastUser = lastUserString ? JSON.parse(lastUserString) : null;

  const userEmail = user?.email ?? lastUser?.email;

  const cacheKey = storeLocal ? (storeWithEmail ? `${userEmail}-${key}` : `${key}`) : null;

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (cacheKey) {
        const storedItem = localStorage.getItem(cacheKey);
        const parsedItem = storedItem
          ? getStoredValueMiddleware(JSON.parse(LZString.decompress(storedItem) || "{}"))
          : initialValue;

        return parsedItem;
      }
      return initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  /*
  This function checks and removes the key from Local Storage if its value is:
  - An empty object
  - An empty array
  - An empty text string
  - An object with all attributes being falsy
  */
  const clearKeyIfEmpty = () => {
    if (cacheKey) {
      const storedItem = localStorage.getItem(cacheKey);
      const parsedItem = storedItem ? JSON.parse(LZString.decompress(storedItem) || "{}") : null;

      if (parsedItem !== null && parsedItem !== undefined) {
        if (
          // If it's an empty array
          (Array.isArray(parsedItem) && parsedItem.length === 0) ||
          // If it's an empty object
          (typeof parsedItem === "object" && Object.keys(parsedItem).length === 0) ||
          // If it's an object with all attributes being falsy
          Object.values(parsedItem).every(value => value === false) ||
          // If it's an empty text string
          (typeof parsedItem === "string" && parsedItem === "")
        ) {
          localStorage.removeItem(cacheKey);
        }
      }
    }
  };

  // Function to set the value, which can be a direct value or a transformation function
  const setValue: SetValue<T> = value => {
    try {
      const newValue =
        typeof value === "function"
          ? (value as CallBack<T>)(storedValue) // If it's a function, apply the transformation
          : value;

      setStoredValue(newValue);

      if (cacheKey) {
        const compressedValue = LZString.compress(JSON.stringify(newValue));
        localStorage.setItem(cacheKey, compressedValue);
        clearKeyIfEmpty();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

export default useBasicLocalStorage;
