import { useMemo } from "react";
import { PBI_TOKEN_URL } from "../../../config";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { queryClient, useMutation, useQuery } from "../../../shared/react-query/react-query-conf";
import { useSessionContext } from "../../session/store/sessionContext";
import {
  CustomUserPbiData,
  DashboardInterface,
  PbiGroupInterface,
} from "../models/powerBiInterfaces";
import { pbiCall } from "../pbi-config";
import { errorAdapter } from "./powerBiAdapters";

//--------------------------------------
//Axios PBI querys
//--------------------------------------

export const POWERBY_STALETIME = 3600000;

//hook create new worspace on power bi group
export const CreatePbiGroup = ({
  pbiToken,
  data,
}: {
  pbiToken: string;
  data: { name: string };
}) => {
  return pbiCall({
    method: "POST",
    headers: {
      Authorization: `Bearer ${pbiToken}`,
    },
    url: "/groups",
    data,
  });
};

export const getPbiAccessToken = (token: string) => {
  return pbiCall({
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${PBI_TOKEN_URL}/pbi`,
  })
    .then(res => res.data)
    .catch(res => res.data);
};

export const getPbiInfo = (endpoint: string, token: string) => {
  return pbiCall({
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `/${endpoint}`,
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      return res;
    });
};

export const getPbiResourceByGroupID = (endpoint: string, groupId: string, token: string) => {
  return pbiCall({
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `/groups/${groupId}/${endpoint}`,
  })
    .then(res => {
      let response = res.data.value;
      return response;
    })
    .catch(res => {
      return res;
    });
};

export const getPbiResourceIngDashboard = (
  endpoint: string,
  groupId: string,
  dashboardId: string,
  token: String
) => {
  return pbiCall({
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `/groups/${groupId}/dashboards/${dashboardId}/${endpoint}`,
  })
    .then(res => {
      let response = res.data.value;
      return response;
    })
    .catch(res => {
      return res;
    });
};

export const addUserInPbiGroup = ({
  groupId,
  data,
  token,
}: {
  groupId: string;
  data: any;
  token: string;
}) => {
  return pbiCall({
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `/groups/${groupId}/users`,
    data,
  })
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
};

//delete PBI GROIUP USER
export const deleteUserInPbiGroup = ({
  groupId,
  user,
  token,
}: {
  groupId: string;
  user: string;
  token: string;
}) => {
  return pbiCall({
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `/groups/${groupId}/users/${user}`,
  })
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
};

//--------------------------------------
//react-Query HOOKS
//--------------------------------------

export const usePbiAccessToken = () => {
  const { pbiToken } = useSessionContext();
  return {
    data: pbiToken,
  };
};

export const useReports = () => {
  const data = queryClient.getQueryData("pbi-reports");
  return data;
};

export const usePbiGroups = () => {
  const { data: token } = usePbiAccessToken();
  const queryKey = "groups";
  return useQuery({
    queryKey: `pbi-${queryKey}`,
    queryFn: () => getPbiInfo(queryKey, token!),
    onError: errorAdapter,
    retry: 0,
    enabled: !!token,
    refetchOnWindowFocus: false,
    staleTime: 20000,
  });
};

export const usePbiReportsInGroup = (customConfig: CustomUserPbiData) => {
  const { data: token } = usePbiAccessToken();
  const groupId = usePbiIdByName(customConfig);
  const queryKey = "reports";
  const query = useQuery({
    queryKey: `pbi-${queryKey}`,
    queryFn: () => getPbiResourceByGroupID(queryKey, groupId, token!),
    select: reports =>
      reports.map((report: any) => ({
        ...report,
        route: report.name,
      })),
    onError: errorAdapter,
    retry: 0,
    enabled: !!groupId && !!token, //&& ONLINE_MODE
    staleTime: 20000,
  });

  return query.data;
};

export const usePbiDashboardsInGroup = (customConfig: CustomUserPbiData) => {
  const { data: token } = usePbiAccessToken();
  const groupId = usePbiIdByName(customConfig);
  const queryKey = "dashboards";
  const { data: dashboards } = useQuery({
    queryKey: `pbi-${queryKey}`,
    queryFn: () => getPbiResourceByGroupID(queryKey, groupId, token!),
    onError: errorAdapter,
    retry: 0,
    enabled: !!groupId && !!token, //&& ONLINE_MODE
  });
  return dashboards;
};

export const usePbiHomeDashboard = (customConfig: CustomUserPbiData) => {
  const dashboards = usePbiDashboardsInGroup(customConfig);
  let homeDashboard = [];
  if (dashboards) {
    homeDashboard = dashboards.filter(
      (item: DashboardInterface) => item.displayName.toLowerCase() === "home"
    );
    if (homeDashboard.length > 0) {
      return homeDashboard[0].id;
    }
  }
};

export const usePbiTilesInDashboard = (customConfig: CustomUserPbiData) => {
  const groupId = usePbiIdByName(customConfig);
  const dashboardId = usePbiHomeDashboard(customConfig);
  const { data: token } = usePbiAccessToken();

  const queryKey = "tiles";
  return useQuery({
    queryKey: `pbi-${queryKey}`,
    queryFn: () => getPbiResourceIngDashboard(queryKey, groupId, dashboardId, token),
    onError: errorAdapter,
    retry: 0,
    enabled: !!dashboardId && !!groupId && !!token, //&& ONLINE_MODE
  });
};

//--------------------------------------
//mutation
//--------------------------------------

export const useAddUserInPbiGroup = () => {
  return useMutation(addUserInPbiGroup);
};
export const useDeleteUserInPbiGroup = () => {
  return useMutation(deleteUserInPbiGroup);
};

//--------------------------------------
//CUSTOM HOOKS
//--------------------------------------

export const useCreatePbiGroup = () => {
  const { data: pbiToken } = usePbiAccessToken();

  const query = useMutation(CreatePbiGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries("groups");
    },
  });

  const status = useStatusProcessor(query);

  const createPbiGroup = (data: { name: string }) => {
    return query.mutateAsync({
      pbiToken,
      data,
    });
  };

  return {
    createPbiGroup,
    ...query,
    ...status,
  };
};

export const usePbiIdByName = (customConfig?: CustomUserPbiData) => {
  const { user, setIsMultiplant, currentPbigroupName } = useSessionContext();
  const filterValue = customConfig?.name || currentPbigroupName.split("-").join(" ");
  const { data: groups } = usePbiGroups();
  const userType = customConfig?.defaultUser || user?.type;

  const groupId = useMemo(
    () =>
      groups?.value?.find((item: PbiGroupInterface) => {
        return item.name == filterValue;
      })?.id,
    [groups, filterValue]
  );

  return useMemo(() => {
    if (userType && groups && filterValue) {
      switch (userType) {
        case "C":
          if (!groups) return null;
          if (!groupId) {
            setIsMultiplant(true);
            alert(
              `El nombre de planta no ha podido ser encontrado - Probablemente haya un error con el nombre de su planta en nuestro sistema; comuniquese con el equipo de soporte para ver que ha ocurrido en la obtencion de los datos de la planta ${filterValue}`
            );
          }
          return groupId;

        default:
          return null;
      }
    }
  }, [filterValue, groupId, groups, userType]);
};
