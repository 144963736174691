import { queryClient } from "../../../shared/react-query/react-query-conf";
import { USER_ROUTES } from "../../../shared/routes/routes";
import { PersonInterface } from "../../person/models/PersonInterface";
import { Plant } from "../../plants/PlantInterface";
import { getPbiAccessToken } from "../../powerBi/services/service.pbi";
import { sessionFactory } from "../model/sessionFactory";
import { Auth, SessionInterface, sessionStore } from "../model/sessionInterface";
import {
  resetCurrentPlant,
  selectPlant,
  sessionReset,
  setError,
  setLoading,
  setPbiToken,
  setPerson,
  setPlants,
  setSessionOperator,
  setSessionStale,
  setStatus,
  setSuccess,
  setToken,
  setUser,
} from "../model/sessionModel";
import {
  DeletePersistedSession,
  getLastinteraction,
  persistedSession,
  persistLastIntercation,
} from "../persistance/sessionPersistance";
import {
  loginAdapter,
  personAdapter,
  plantsAdapter,
  userAdapter,
} from "../services/sessionAdapters";

export const INITIAL_SESSION: SessionInterface = {
  ...persistedSession(),
};

export const SESSION_DURATION = 3600000; // 3600000ms => 1 hora

export const selectNewPlantUseCase = (store: sessionStore, plant: Plant) => {
  const selectedPlantObj = selectPlant(store.session, plant);
  store.setSession(selectedPlantObj);
  /*  PersistSession(selectedPlantObj) */
};

export const resetPlantSelectorUseCase = (store: sessionStore) => {
  const resetedPlantSelectorObj = resetCurrentPlant(store.session);
  store.setSession(resetedPlantSelectorObj);
  /* PersistSession(resetedPlantSelectorObj) */
  queryClient.removeQueries("pbi-reports");
  queryClient.removeQueries("pbi-groups");
};

export const newSession = async (authData: Auth): Promise<SessionInterface> => {
  //PrepareNewSession
  let session = sessionReset();

  //Get logged in
  await loginAdapter(authData)
    .then(async ({ token }) => {
      session = setToken(session, token);

      await getPbiAccessToken(token).then(token => {
        session = setPbiToken(session, token);
      });

      //Pepare user
      await userAdapter(token).then(user => {
        session = setUser(session, user);
      });

      //Pepare person
      await personAdapter(token).then(person => {
        if (person.role === "Operario") {
          session = setSessionOperator(session, person);
        }
        session = setPerson(session, person);
      });

      //Pepare plants
      await plantsAdapter(token).then(plants => {
        session = setPlants(session, plants);
        session = setSuccess(session, "");
      });
    })
    .catch(({ message }) => {
      session = setError(session, message);
    });

  return session;
};

export const getlogedIn = (store: sessionStore, data: Auth, onSuccess: () => void) => {
  store.setSession(setLoading(store.session));
  newSession(data).then(session => {
    switch (session.status) {
      case "error":
        store.setSession(setError(store.session, session.message));
        DeletePersistedSession();
        break;

      case "success":
        store.setSession(session);
        /* PersistSession(session) */
        persistLastIntercation();
        onSuccess();
    }
  });
};

export const sessionTimeData = () => {
  const nowTime = new Date().getTime();
  const lastInteraction = getLastinteraction().miliseconds;
  const transcurredTime = nowTime - lastInteraction;
  const isSessionStale = transcurredTime > SESSION_DURATION;
  const isSameDay = transcurredTime < 8 * 60 * 60 * 1000; // 8*60*60*1000 => 8 hs
  return { isSessionStale, isSameDay, transcurredTime };
};

export const validateSession = (store: sessionStore) => {
  const { isSessionStale, isSameDay } = sessionTimeData();
  if (isSessionStale) {
    if (isSameDay) {
      const newSession = setStatus(setSessionStale(store.session, true), "idle");
      store.setSession(newSession);
      return false;
    } else {
      store.setSession(sessionFactory());
      return false;
    }
  } else {
    persistLastIntercation();
    return true;
  }
};

export const restoreNewSession = (store: sessionStore) => {
  const person = persistedSession().person;
  const location = window.location;

  if (person) {
    const isSameUser = location.pathname.split("/").includes(person.role);
    console.log(isSameUser, person.role, location.pathname);
    if (isSameUser) {
      console.log("Session restored", new Date());
      queryClient.resetQueries();
    } else {
      console.log("Session restored with different user: " + person.role, new Date());
      location.replace(`/${USER_ROUTES.home}`);
    }
  } else {
    console.log("user kickout");
    getLogedOut(store);
  }
};

export const restoreSession = (sessionStore: sessionStore) => {
  const lastIteraction = getLastinteraction();
  if (
    !sessionTimeData().isSessionStale &&
    !sessionStore.session.sessionStale &&
    sessionStore.session.token
  ) {
    window.location.replace(lastIteraction.value.pathname);
  } else {
    sessionStore.setSession(sessionReset());
    DeletePersistedSession();
  }
};

export const getLogedOut = (store: sessionStore) => {
  store.setSession(sessionReset());
  persistLastIntercation();
  window.location.replace("/");
  DeletePersistedSession();
};

export const setSelectedOperatorController = (store: sessionStore, operator?: PersonInterface) => {
  const newSession = setSessionOperator(store.session, operator);
  store.setSession(newSession);
  /* PersistSession(newSession) */
};
