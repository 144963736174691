import { useEffect, useState } from "react";
import { QueryStatus } from "react-query";
import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config";
import { PrivateQuery, PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { queryClient, useMutation, useQuery } from "../../../shared/react-query/react-query-conf";
import { useAllAuxByPlant } from "../../allAux/services/allAuxServices";
import { EquipmentInterface } from "../../lubricationPoints/model/lubricationPointsInterface";
import { useEquipmentsByComponent } from "../../lubricationPoints/services/lubricationPointsAdapters";
import { useToken } from "../../session/store/sessionStore";
import { ComponentInterface } from "../models/ComponentInterface";

export const ComponentsCRUDDependencies = ["AllComponents", "PlantAux", "componentsByPlant"];

//----------------------------
//repo
//---------------------------------------------

export const AllComponents = ({ token }: PrivateQuery) => {
  return apiCall({
    method: "GET",
    url: "/AllComponents",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const CreateComponent = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    url: "/CreateComponent",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
export const UpdateComponent = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    url: "/UpdateComponent",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const DeleteComponent = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    url: "/DeleteComponent",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

//----------------------------
// ReactQuery
//---------------------------------------------

export const useAllComponents = () => {
  const token = useToken();
  return useQuery({
    queryKey: "AllComponents",
    queryFn: () => AllComponents({ token }),
  });
};

export const usePlantComponents = () => {
  const query = useAllAuxByPlant();
  return {
    ...query,
    data: query.data?.components,
  };
};

//----------------------------
//Mutations
//---------------------------------------------

export const useCreateComponent = () => {
  const token = useToken();

  const query = useMutation(CreateComponent, {
    onSuccess: () =>
      ComponentsCRUDDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      }),
  });

  const createComponent = (data: ComponentInterface) => {
    return query.mutate({
      data,
      token,
    });
  };

  const status = useStatusProcessor(query);
  return { createComponent, ...query, ...status };
};

export const useUpdateComponent = () => {
  const token = useToken();

  const query = useMutation(UpdateComponent, {
    onSuccess: () =>
      ComponentsCRUDDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      }),
  });

  const updateComponent = (data: ComponentInterface) => {
    return query.mutate({
      data,
      token,
    });
  };

  const status = useStatusProcessor(query);
  return { updateComponent, ...query, ...status };
};

//----------------------------
//delete
//---------------------------------------------

export const useDeleteComponent = () => {
  return useMutation(DeleteComponent, {
    onSuccess: () =>
      ComponentsCRUDDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      }),
  });
};

interface DeleteComponentErrors {
  equipments?: EquipmentInterface[] | undefined | null;
}

export const useDeleteComponentVerification = () => {
  const token = useToken();
  const query = useDeleteComponent();
  const { mutate: deleteItem } = query;
  const [status, setStatus] = useState<QueryStatus>("idle");
  const [validationElement, setValidationElement] = useState<ComponentInterface | {} | undefined>();
  const {
    data: equipmentsByComponents,
    status: equipmentsByComponentStatus,
    remove: removeEquipmentsByComponents,
  } = useEquipmentsByComponent(validationElement);
  const [errors, setErrors] = useState<DeleteComponentErrors>({});

  const handleResetValidations = () => {
    removeEquipmentsByComponents();
  };

  const validate = (item: ComponentInterface) => {
    setStatus("loading");
    handleResetValidations();
    setErrors({});
    setValidationElement(item);
  };

  const handleDelete = () => {
    if (equipmentsByComponentStatus === "success") {
      if (equipmentsByComponents && equipmentsByComponents.length > 0) {
        setErrors({ equipments: equipmentsByComponents });
        setStatus("error");
        setValidationElement(undefined);
      } else {
        deleteItem(
          {
            data: validationElement,
            token,
          },
          {
            onSuccess: () => {
              setStatus("success");
              setValidationElement(undefined);
            },
            onError: err => console.log(err),
          }
        );
      }
    }
  };

  useEffect(() => {
    validationElement && handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentsByComponentStatus]);

  return {
    errors,
    status,
    validate,
    query,
  };
};
