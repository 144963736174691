import { CircularProgress, Divider, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useEffect, useState } from "react";
import SelectSimpleEntity from "../../components/autocomplete/old/SelectSimpleEntity";
import { CustomIcon } from "../../components/buttons/CustomIcon";
import FlexContainer from "../../components/containers/FlexContainer";
import TgdTabs from "../../components/tabs/TgdTabs";
import { FactoryInterface } from "../../domains/factories/model/FactoryInterface";
import SelectFactory from "../../domains/factories/SelectFactory";
import { Plant } from "../../domains/plants/PlantInterface";
import { usePlantsByFactory } from "../../domains/plants/plantsService";
import { useSessionContext } from "../../domains/session/store/sessionContext";
import NewsPage from "./NewsPage";
import PlantManagement from "./PlantManagement";
import StockPage from "./stock/StockPage";
import ReportsPage from "./supervisor/home/ReportsPage";

interface Props {}

const DiscoverPage: React.FC<Props> = ({}) => {
  const features = [
    { icon: "error", label: "novedades", component: () => <NewsPage /> },
    { icon: "search", label: "reportes", component: () => <ReportsPage /> },
    { icon: "stock", label: "stock", component: () => <StockPage /> },
    /* {icon:'wifi',label:'sensorizado',component:(plant:Plant)=><>
            <RealtimeSensoringPage tagFP={plant.tagFP}/>
            <VersionContainer company = 'STI'/>
        </>}, */
    { icon: "config", label: "planta", component: () => <PlantManagement /> },
    /* {icon:'config',label:'sti',component:(plant:Plant)=><SensorsManagementPage tagFP={plant.tagFP} />}, */
  ];

  const { currentFactory, setNewPlant, selectFactory } = useSessionContext();
  const [feature, setFeature] = useState();
  const { data: plants, status } = usePlantsByFactory(currentFactory?.tagF);

  useEffect(() => {
    if (plants && plants?.length > 0 && currentFactory) {
      setNewPlant(plants[0]);
    }
  }, [currentFactory?.tagF]);

  const tabs = [
    ...(plants
      ? plants.map((p: Plant) => ({
          label: p.name,
          plant: p,
          component: features.find(f => f.label === feature)?.component(),
        }))
      : []),
  ];

  return (
    <>
      <FlexContainer justify="space-between">
        <FlexContainer>
          <SelectFactory
            value={currentFactory?.name || ""}
            label="Fábrica"
            onChange={(item: FactoryInterface) => selectFactory(item)}
          />
          <SelectSimpleEntity
            entityList={features}
            onChange={(value: any) => setFeature(value)}
            label="Panel"
            showTitle="label"
            renderOption={f => (
              <ListItem>
                <ListItemIcon>
                  {" "}
                  <CustomIcon icon={f.icon} />
                </ListItemIcon>
                <ListItemText>{f.label}</ListItemText>
                <Divider />
              </ListItem>
            )}
          />
          {status === "loading" && <CircularProgress size="24px" />}
        </FlexContainer>
        {/*  <CustomButton icon='config' variant='iconButton' color='inherit' popoverTitle='Gestionar fábrica' /> */}
      </FlexContainer>
      <br />

      <TgdTabs
        key={currentFactory?.name}
        items={tabs}
        labelIterator="label"
        content={({ component }) => component}
        props={{
          onClick: (item: any) => (item.plant ? setNewPlant(item.plant) : null),
        }}
      />
    </>
  );
};

export default DiscoverPage;
