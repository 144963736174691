import { useQuery, useMutation } from "react-query";
import { useSessionContext } from "../../../../../domains/session/store/sessionContext";
import { useToken } from "../../../../../domains/session/store/sessionStore";
import { apiCall, mutateApiCall } from "../../../../../shared/axios/axios-config";
import {
  PrivateQueryTagFPInterface,
  PrivateQueryData,
} from "../../../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../../../shared/queries/StatusProcessor";
import { queryClient } from "../../../../../shared/react-query/react-query-conf";
import { BrandInterface } from "../types/brandTypes";

const BASE_URL = "/Brand";
const AUTH_HEADER = (token: string) => ({
  Authorization: `Bearer ${token}`,
});

export const GetAllBrands = ({ token, tagFP }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: BASE_URL,
    headers: AUTH_HEADER(token),
    params: { tagFP },
  });
};

export const CreateBrand = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

export const UpdateBrand = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "PUT",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

export const DeleteBrand = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "DELETE",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

// -------------------------------------------------------------------------------------------------

export const useGetAllBrands = () => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: "GetAllBrands",
    queryFn: () => GetAllBrands({ token, tagFP }),
    enabled: !!token,
  });
};

export const useCreateBrand = () => {
  const token = useToken();

  const createBrandQuery = useMutation(CreateBrand, {
    onSuccess: () => {
      queryClient.invalidateQueries("GetAllBrands");
    },
  });

  const createBrand = (data: BrandInterface) => {
    return createBrandQuery.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(createBrandQuery);

  return {
    createBrand,
    ...createBrandQuery,
    ...status,
    resetStatus: status.reset,
  };
};

export const useUpdateBrand = () => {
  const token = useToken();

  const updateBrandQuery = useMutation(UpdateBrand, {
    onSuccess: () => {
      queryClient.invalidateQueries("GetAllBrands");
    },
  });

  const updateBrand = (data: BrandInterface) => {
    return updateBrandQuery.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(updateBrandQuery);

  return {
    updateBrand,
    ...updateBrandQuery,
    ...status,
    resetStatus: status.reset,
  };
};

export const useDeleteBrand = () => {
  const token = useToken();

  const deleteBrandQuery = useMutation(DeleteBrand, {
    onSuccess: () => {
      queryClient.invalidateQueries("GetAllBrands");
    },
  });

  const deleteBrand = (data: BrandInterface) => {
    return deleteBrandQuery.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(deleteBrandQuery);

  return {
    deleteBrand,
    ...deleteBrandQuery,
    ...status,
    resetStatus: status.reset,
  };
};
