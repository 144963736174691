import { Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import DuoContainer from "../../../components/containers/DuoContainer";
import FlexContainer from "../../../components/containers/FlexContainer";
import { useModal } from "../../../components/containers/modal/Modal/hooks/useModal";
import TGDModal from "../../../components/containers/modal/TGDModal/TGDModal";
import PageContainer from "../../../components/containers/PageContainer";
import SidebarContainer from "../../../components/containers/SidebarContainer";
import EntityList from "../../../components/grids/EntityList";
import OnlineOfflineMode from "../../../shared/config/offline Mode/OnlineOfflineMode";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import AssignCancelPlantsRefactor from "../../plants/AssignCancelPlantsRefactor";
import { useSessionContext } from "../../session/store/sessionContext";
import { PersonInterface } from "../models/PersonInterface";
import { useClientsByReferer } from "../services/personServices";
import AddPersonForm from "./AddPersonForm";
import UpdatePersonForm from "./UpdatePersonForm";

interface Props {}

const AddClientRefactor: React.FC<Props> = ({}) => {
  useSessionContext();
  const { lang } = useLangLabels();
  const [clients, { status: clientsStatus }] = useClientsByReferer();

  const { isOpen, toggleModal } = useModal();
  const [focusPersonData, setFocusPersonData] = useState<any>();

  const handlePerson = (createdPerson?: PersonInterface) => {
    setFocusPersonData(createdPerson);
    toggleModal();
  };

  const assignPlants = (item: any) => {
    toggleModal();
    sessionStorage.setItem("editUserEmail", item.email);
    setFocusPersonData(item);
  };

  const customButtons = (item: any) => {
    return (
      <CustomButton
        /* style={{margin:'0 0'}} */
        icon="business"
        variant="iconButton"
        popoverTitle={lang.plants.asignCancelPlants}
        action={() => assignPlants(item)}
        color={"primary"}
      />
    );
  };

  return (
    <OnlineOfflineMode>
      <SidebarContainer>
        <EntityList
          title={lang.persons.clients}
          icon={"person"}
          status={clientsStatus}
          items={clients}
          customButtons={customButtons}
          dropDownChildren={item => (
            <PageContainer>
              <UpdatePersonForm person={item} />
            </PageContainer>
          )}
        />
        <Paper>
          <FlexContainer border={"4px 0px 0px solid red"} />
          <PageContainer padding="16px 16px 0px">
            <Typography component={"h6"}>{lang.persons.newClient}</Typography>
          </PageContainer>
          <AddPersonForm role="Cliente" handlePerson={handlePerson} />
        </Paper>
      </SidebarContainer>

      <TGDModal title={lang.plants.asignCancelPlants} open={isOpen} handleClose={toggleModal}>
        <DuoContainer>
          <AssignCancelPlantsRefactor
            action="assign"
            roleToEdit={"C"}
            title={lang.plants.clickToAssign}
            userData={focusPersonData}
          />
          <AssignCancelPlantsRefactor
            userData={focusPersonData}
            roleToEdit={"C"}
            action={"cancel"}
            title={lang.plants.clickToCancel}
          />
        </DuoContainer>
      </TGDModal>
    </OnlineOfflineMode>
  );
};

export default AddClientRefactor;
