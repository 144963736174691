import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Chip,
  CircularProgress,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect } from "react";
import { CustomIcon } from "../../components/buttons/CustomIcon";
import Drawer from "../../components/containers/drawer/Drawer";
import FlexContainer from "../../components/containers/FlexContainer";
import { useModal } from "../../components/containers/modal/Modal/hooks/useModal";
import PageContainer from "../../components/containers/PageContainer";
import EntityProperties from "../../components/EntityProperties";
import TgdTabs from "../../components/tabs/TgdTabs";
import ClientVisorPanel from "../../pages/backoffice/ClientVisor/ClientVisor";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { PersonInterface } from "../person/models/PersonInterface";
import { usePlantClients } from "../person/services/personServices";
import CreatePlantForm from "../plants/CreatePlantForm";
import { Plant } from "../plants/PlantInterface";
import { usePlantsByTagF } from "../plants/plantsService";
import { usePlantGridModel } from "../plants/useplantGRidmodel";
import { FactoryInterface } from "./model/FactoryInterface";

const PlantClients: React.FC<{ tagFP: string }> = ({ tagFP }) => {
  const { data: clients } = usePlantClients(tagFP);

  return (
    <FlexContainer flexWrap="wrap">
      {clients?.map((c: PersonInterface) => (
        <Chip style={{ marginRight: "12px" }} label={c.email} icon={<CustomIcon icon={"user"} />} />
      ))}
    </FlexContainer>
  );
};

const FactoryConfig: React.FC<{ factory?: FactoryInterface; onClose: () => void }> = ({
  factory,
  onClose,
}) => {
  const plantsGridModel = usePlantGridModel();
  const { lang } = useLangLabels();

  const { isOpen, toggleModal } = useModal();
  const { data: Plants } = usePlantsByTagF(factory?.tagF);

  useEffect(() => {
    factory && toggleModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factory]);

  const handleClose = () => {
    toggleModal();
    onClose();
  };

  //accordion
  const [expanded, setExpanded] = React.useState(0);

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Drawer
      mode="content"
      styles={{ drawerWidth: "50vw" }}
      anchor={"right"}
      open={isOpen}
      onClose={handleClose}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6">{factory?.name}</Typography>
        </Toolbar>
      </AppBar>

      <PageContainer>
        <FlexContainer justify="space-between">
          <ListItem>
            <ListItemAvatar>
              <CustomIcon icon="map" />
            </ListItemAvatar>
            <ListItemText primary={factory?.location} secondary={lang.factories.location} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <CustomIcon icon="helmet" />
            </ListItemAvatar>
            <ListItemText primary={factory?.referrer} secondary={lang.factories.referrer} />
          </ListItem>
        </FlexContainer>
      </PageContainer>
      <Divider />
      {Plants && factory ? (
        <TgdTabs
          padding="0px"
          {...{
            items:
              Plants?.map((plant: Plant) => ({
                name: plant.name,
                component: (
                  <>
                    <Accordion expanded={expanded === 1} onChange={handleChange(1)}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <ListItemAvatar>
                          <CustomIcon icon="info" />
                        </ListItemAvatar>
                        <ListItemText>
                          <FlexContainer>Info</FlexContainer>
                        </ListItemText>
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails>
                        <EntityProperties entity={plant} gridModel={plantsGridModel} />
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 2} onChange={handleChange(2)}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {/* <ListItem> */}
                        <ListItemAvatar>
                          <CustomIcon icon="user" />
                        </ListItemAvatar>
                        <ListItemText>{lang.persons.clients}</ListItemText>
                        {/* </ListItem> */}
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails>
                        <PlantClients tagFP={plant.tagFP} />
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 3} onChange={handleChange(3)}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <ListItemAvatar>
                          <CustomIcon icon="more" />
                        </ListItemAvatar>
                        <ListItemText>
                          <FlexContainer>{lang.words.more}...</FlexContainer>
                        </ListItemText>
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails>
                        <ClientVisorPanel {...{ plant }} />
                      </AccordionDetails>
                    </Accordion>
                  </>
                ),
              })) || [],
            customTabComponent: <CreatePlantForm factory={factory} />,
            popoverTitle: `${lang.actionTypes.create} ${lang.plants.singular.toLowerCase()}`,
            icon: "add",
            content: value => value.component,
            labelIterator: "name",
          }}
        />
      ) : (
        <FlexContainer height="50vh" align="center" justify="center">
          <CircularProgress size="24px" />
        </FlexContainer>
      )}
    </Drawer>
  );
};

export default FactoryConfig;
