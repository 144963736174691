import { GridModelInterface } from "../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

export const usePlantGridModel = () => {
  const { lang } = useLangLabels();

  const gridModelPlants: GridModelInterface = {
    factory: { label: lang.plants.factory },
    factoryId: { label: lang.plants.factoryId },
    id: { label: lang.globalentityValues.id },
    location: { label: lang.plants.location },
    name: { label: lang.plants.name },
    plantId: { label: lang.plants.plantId },
    referrer: { label: lang.plants.referrer },
    tagFP: { label: lang.globalentityValues.tagFP },
    state: {
      label: lang.plants.state,
      options: [
        { name: lang.words.active.toUpperCase(), value: true },
        { name: lang.words.inactive.toUpperCase(), value: false },
      ],
    },
  };
  return gridModelPlants;
};
