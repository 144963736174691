import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { Plant } from "../../plants/PlantInterface";
import { EquipmentInterface, LubricationPointInterface } from "../model/lubricationPointsInterface";
import { QueryStatus } from "react-query";
import { useAllAuxByPlant } from "../../allAux/services/allAuxServices";
import DefaultFormLayout from "../../../components/form/DefaultFormLayout/DefaultFormLayout";
import SelectElement from "../../elements/molecules/SelectElement";
import SelectComponent from "../../components/components/SelectComponent";
import SelectLubricant from "../../lubricant/molecules/SelectLubricant";
import SelectTypeBy from "../../types/components/SelectTypeBy";
import { enumTgdTypes } from "../../types/models/types";
import SelectSimpleEntity from "../../../components/autocomplete/old/SelectSimpleEntity";
import { EquipmentFormRegex } from "../../../shared/regExp/regExp";
import { lubricationPointVerificationArray } from "../services/lubricationPointsAdapters";
import SelectSuggestedQuantity from "./actions/SelectSuggestedQuantity";
import { ButtonVariant } from "../../../components/buttons/types/CustomButtonTypes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface Props {
  plant: Plant;
  equipment: EquipmentInterface | null | undefined;
  defaultState?: LubricationPointInterface;
  handleLubricationPoint: any;
  points: LubricationPointInterface[];
  variant?: ButtonVariant;
}

const useCustomStatus = () => {
  const [status, setStatus] = useState<QueryStatus>("idle");
  const [error, setError] = useState<QueryStatus | boolean>(false);
  const [_message, setMessage] = useState<{ error: any; success: any }>({
    error: undefined,
    success: undefined,
  });

  const handleStatus = (status: "error" | "success" | "loading", message?: string) => {
    status === "error" && setError("error");
    setStatus("error");
    setMessage({
      ..._message,
      error: message,
    });

    status === "loading" && setStatus("loading");

    status === "success" && setStatus("success");
    setMessage({
      ..._message,
      error: message,
    });
  };

  const messageExport = () => (status === "success" ? _message.success : _message.error);

  return {
    status,
    message: messageExport(),
    error,
    handleStatus,
  };
};

const CreateEquipmentForm: React.FC<Props> = ({
  plant,
  points,
  equipment,
  defaultState,
  handleLubricationPoint,
  variant,
}) => {
  const { lang } = useLangLabels();
  const _tagFP = plant?.tagFP;

  const { data: plantAux } = useAllAuxByPlant();
  const lubricants = plantAux?.lubricants;

  const { register, handleSubmit, setValue, watch, control, errors, reset } = useForm({
    shouldUnregister: false,
  });

  const { handleStatus, status, message, error } = useCustomStatus();

  const selectedLubricant = lubricants?.find((l: any) => l.lubricant === watch().lubricant);

  const onSubmit = (body: LubricationPointInterface) => {
    handleStatus("loading");
    handleLubricationPoint([
      ...points,
      {
        frontId: Date(),
        tagTGD: equipment?.tagTGD,
        sector: equipment?.sector,
        equipment: equipment?.equipment,
        plantTag: equipment?.plantTag,
        tagFP: equipment?.tagFP,
        type: equipment?.type,
        criticality: equipment?.criticality,
        brand: body.brand,
        model: body.model,
        capacity: body.capacity,
        component: body.component,
        element: body.element,
        function: body.function || lang.stringDefaultValues.notSpecified.toUpperCase(),
        location: body.location,
        lubricant: body.lubricant,
        lubricantType: selectedLubricant?.type,
        measureUnit: selectedLubricant?.measureUnit,
        observations: body.observations || lang.stringDefaultValues.notSpecified.toUpperCase(),
        oilAnalysis: !!(body.oilAnalysis === "SI"),
        review: 1,
        suggestedQuantity: body.suggestedQuantity,
      },
    ]);
    handleStatus("success", "Bien hecho");
    reset();
  };

  return (
    <DefaultFormLayout
      buttonVariant={variant || "fab"}
      queryStatus={status}
      alertStatusMessage={message}
      error={error}
      buttonAction={handleSubmit(onSubmit)}
      buttonText={lang.filters.add + " " + lang.lubricationPoints.component}
    >
      <TextField
        inputRef={register({
          required: { value: true, message: lang.validations.messages.required },
        })}
        name="brand"
        placeholder={lang.lubricationPoints.componentBrand}
        defaultValue={defaultState?.brand || ""}
        variant="outlined"
        label={`${lang.lubricationPoints.componentBrand} ${errors?.brand?.message || ""}`}
        color={errors?.brand?.message ? "primary" : "secondary"}
        size="small"
      />

      <TextField
        inputRef={register({
          required: { value: true, message: lang.validations.messages.required },
        })}
        name="model"
        defaultValue={defaultState?.model || ""}
        variant="outlined"
        label={`${lang.lubricationPoints.componentModel} ${errors?.model?.message || ""}`}
        color={errors?.model?.message ? "primary" : "secondary"}
        size="small"
      />

      <TextField
        name="function"
        defaultValue={defaultState?.function || ""}
        variant="outlined"
        label={lang.lubricationPoints.componentFunction}
        color={errors?.function?.message ? "primary" : "secondary"}
        size="small"
      />

      <TextField
        inputRef={register()}
        name="location"
        defaultValue={defaultState?.location || ""}
        variant="outlined"
        label={lang.lubricationPoints.componentLocation}
        color={"secondary"}
        size="small"
      />

      <TextField
        inputRef={register()}
        name="observations"
        defaultValue={defaultState?.location || ""}
        variant="outlined"
        label={lang.lubricationPoints.observations}
        color={"secondary"}
        size="small"
      />

      <Controller
        as={
          <SelectElement
            tagFP={_tagFP}
            error={errors?.element?.message}
            onChange={value => setValue("element", value)}
            defaultValue={watch("element")}
          />
        }
        name="element"
        defaultValue={defaultState?.element || ""}
        rules={{
          required: { value: true, message: lang.validations.messages.required },
        }}
        control={control}
      />

      <Controller
        as={
          <SelectComponent
            tagFP={_tagFP}
            error={errors?.component?.message}
            onChange={(value: string) => setValue("component", value)}
            defaultValue={watch("component")}
          />
        }
        name="component"
        defaultValue={defaultState?.component || ""}
        rules={{
          required: { value: true, message: lang.validations.messages.required },
          validate: {
            exist: () =>
              lubricationPointVerificationArray(points, watch("element"), watch("component")),
          },
        }}
        control={control}
      />

      <Controller
        as={
          <SelectLubricant
            tagFP={_tagFP}
            error={errors?.lubricant?.message}
            onChange={(value: string) => setValue("lubricant", value)}
            defaultValue={watch("lubricant")}
          />
        }
        name="lubricant"
        defaultValue={defaultState?.lubricant || ""}
        rules={{ required: { value: true, message: lang.validations.messages.required } }}
        control={control}
      />

      <TextField
        inputRef={register({
          required: { value: true, message: lang.validations.messages.required },
          pattern: {
            value: EquipmentFormRegex.float,
            message: lang.validations.messages.invalidNumber,
          },
        })}
        name="capacity"
        placeholder={lang.lubricationPoints.lubricantCapacity}
        defaultValue={defaultState?.capacity || ""}
        variant="outlined"
        label={errors?.capacity?.message || lang.lubricationPoints.lubricantCapacity}
        color={errors?.capacity?.message ? "primary" : "secondary"}
        size="small"
      />

      <Controller
        as={
          <SelectTypeBy
            disabled
            error={errors?.measureUnit?.message}
            by={{ key: "type", value: enumTgdTypes.unity }}
            tagFP={_tagFP}
            onChange={() => {}}
            defaultValue={selectedLubricant?.measureUnit}
          />
        }
        name="measureUnit"
        defaultValue={defaultState?.measureUnit}
        control={control}
      />

      <Controller
        as={
          <SelectSimpleEntity
            error={errors?.oilAnalysis?.message}
            label={lang.lubricationPoints.oilAnalysis}
            entityList={[
              { label: "SI", value: true },
              { label: "NO", value: false },
            ]}
            showTitle={"label"}
            onChange={setValue}
            defaultValue={watch("oilAnalysis")}
          />
        }
        name="oilAnalysis"
        defaultValue={defaultState?.oilAnalysis || ""}
        rules={{ required: { value: true, message: lang.validations.messages.required } }}
        control={control}
      />

      <Controller
        as={
          <SelectSuggestedQuantity
            defaultValue={`0 ${selectedLubricant?.measureUnit ?? ""}`}
            onChange={value => setValue("suggestedQuantity", value)}
          />
        }
        name="suggestedQuantity"
        rules={{ required: { value: true, message: lang.validations.messages.required } }}
        control={control}
      />
    </DefaultFormLayout>
  );
};

export default CreateEquipmentForm;
