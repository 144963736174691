import React, { useEffect, useState } from "react";
//components

//context
//MATERIAL
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { SIDEBAR_WIDTH, TRANSITION_DURATION } from "../LayoutConstants";
import { Chip, Hidden, Typography } from "@material-ui/core";
import DeviceSyncPanel from "../../domains/SyncBox/components/DeviceSyncPanel";
import { useSessionContext } from "../../domains/session/store/sessionContext";
import { AccountCircle } from "@material-ui/icons";
import SelectLanguageForm from "../../shared/lang/SelectLanguageForm";
import NavbarLogo from "../../assets/img/navBarLogo.png";
import NotificationsNavBar from "../../domains/notifications/components/NotificationsNavBar";
import { USER_ROUTES } from "../../shared/routes/routes";
import { NavLink } from "react-router-dom";
import MenuPlantSelector from "../../pages/backoffice/ClientVisor/MenuPlantSelector";
import { MODE, MODES } from "../../config";
import InstrumentDeviceSyncPanel from "../../modules/instruments/domain/sync/components/InstrumentDeviceSyncPanel";

const useStyles = makeStyles(theme =>
  createStyles({
    hamButton: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    appBar: (props: any) => ({
      width: `calc(100% - ${props.drawerWidth}px)`,
      transition: `width ease-in-out ${TRANSITION_DURATION}s`,
      //marginLeft:`${props.drawerWidth}px`
    }),
    title: {
      position: "relative",
      flexGrow: 1,
      margin: "0 8px",
    },
    spacing: {
      margin: "0 24px",
    },
    colorBlack: {
      color: "#333",
    },
    /* menu notification */
    toggleNotifications: {
      "&:hover": {
        backgroundColor: "#f6f6f6",
      },
    },
  })
);

const NavBar: React.FC<{ action: () => void; open: boolean; device?: "mobile" }> = ({
  device,
  open,
  action,
}) => {
  const { user, plants } = useSessionContext();
  const [drawerWidth, setDrawerWidth] = useState(open ? 0 : SIDEBAR_WIDTH);
  const classes = useStyles({ drawerWidth: device ? 0 : drawerWidth });
  const theme = useTheme();

  useEffect(() => {
    setDrawerWidth(open ? 250 : 0);
  }, [open]);

  const menuButton = (
    <IconButton onClick={action}>
      {theme.direction === "rtl" ? <MenuIcon /> : <MenuIcon />}
    </IconButton>
  );

  const renderDeviceSyncPanel = () => {
    switch (MODE) {
      case MODES.LUBRICATION:
        return DeviceSyncPanel();
      case MODES.INSTRUMENTS:
        return InstrumentDeviceSyncPanel();
      default:
        return null;
    }
  };

  return (
    <AppBar
      elevation={0}
      aria-label="menu"
      className={classes.appBar}
      position="fixed"
      color="transparent"
      style={{ backgroundColor: "#fff", zIndex: 900 }}
    >
      <Toolbar /* className={classes.appBar} */>
        {menuButton}

        {user && user?.email === "d.lavallen@tgdsa.com" && (
          <Chip
            component={NavLink as any}
            color={"primary"}
            label="Prospectos / Demo"
            clickable
            to={`${USER_ROUTES.demoUsers}`}
          />
        )}

        <img src={NavbarLogo} style={{ height: "40px", left: "24px" }} alt="TGD Soft" />

        <Hidden mdUp>
          {user && user?.type === "C" && plants && plants.length > 1 ? (
            <MenuPlantSelector color="secondary" />
          ) : null}
        </Hidden>

        <Typography variant="subtitle1" noWrap className={classes.title}>
          {" "}
        </Typography>

        <Hidden smDown>
          <AccountCircle />
          {user && (
            <Typography variant="button" noWrap className={classes.spacing}>
              {" "}
              {user.name}{" "}
            </Typography>
          )}
        </Hidden>
        <Hidden smDown>
          <NotificationsNavBar />
        </Hidden>

        <SelectLanguageForm />

        {(user?.type === "S" || user?.type === "O") && renderDeviceSyncPanel()}
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export default NavBar;
