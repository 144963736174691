import { useEffect, useMemo, useState } from "react";
import { MutateOptions, QueryStatus } from "react-query";
import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config";
import { MutateResponse } from "../../../shared/axios/models/MutateResponse";
import {
  PrivateQuery,
  PrivateQueryData,
  PrivateQueryDataTagFPInterface,
} from "../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { queryClient, useMutation, useQuery } from "../../../shared/react-query/react-query-conf";
import { useGetAllAuxByPlantData } from "../../allAux/services/allAuxServices";
import { ContainersInterface } from "../../containers/model/ContainerInterface";
import { useContainersByLubricantTypeAndLubricant } from "../../containers/services/service.containers";
import {
  EquipmentInterface,
  LubricationPointInterface,
} from "../../lubricationPoints/model/lubricationPointsInterface";
import { useEquipmentsByLubricant } from "../../lubricationPoints/services/lubricationPointsAdapters";
import { useSessionContext } from "../../session/store/sessionContext";
import { useToken } from "../../session/store/sessionStore";
import { TypeInterface } from "../../types/models/TypeInterface";
import { LubricantInterface } from "../model/LubricantInterface";

export const CreateLubricant = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/CreateLubricant",
    data,
  });
};

export const UpdateLubricant = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/UpdateLubricant",
    data,
  });
};

export const DeleteLubricant = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/DeleteLubricant",
    data,
  });
};

export const getAllLubricants = ({ token }: PrivateQuery) => {
  return apiCall({
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/AllLubricans",
  });
};

export const getLubricantsByType = ({ data: type, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/LubricantByType",
    data: {
      tagFP: type.tagFP,
      name: type.name,
    },
  });
};

export const getDailyLubricants = ({ data, tagFP, token }: PrivateQueryDataTagFPInterface) => {
  return apiCall({
    method: "post",
    url: `/LubricantsAndLubricationPoints`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
  });
};

//----------------------------
//react-query
//---------------------------------------------

export const useAllLubricants = () => {
  const token = useToken();
  return useQuery({
    queryKey: "Lubricants",
    queryFn: () => getAllLubricants({ token: token! }),
    enabled: !!token,
  });
};

export const useAllLubricantsByPlant = () => {
  return useGetAllAuxByPlantData("lubricants");
};

export const useLubricantsByType = (type?: TypeInterface) => {
  const token = useToken();
  return useQuery({
    queryKey: "LubricantsByType",
    queryFn: () => getLubricantsByType({ data: type, token: token! }),
    enabled: !!token && !!type,
    staleTime: 1,
  });
};

//------------------------------
//MUTATIONS

export const useCreateLubricant = () => {
  const token = useToken();
  const query = useMutation(CreateLubricant, {
    onSuccess: () => {
      queryClient.invalidateQueries("PlantAux");
      queryClient.invalidateQueries("Lubricants");
    },
  });

  const createLubricant = (data: LubricantInterface) => {
    return query.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(query);

  return {
    createLubricant,
    ...query,
    ...status,
  };
};

export const useUpdateLubricant = () => {
  const token = useToken();

  const query = useMutation(UpdateLubricant, {
    onSuccess: () => {
      queryClient.invalidateQueries("PlantAux");
      queryClient.invalidateQueries("Lubricants");
    },
  });

  const updateLubricant = (
    data: LubricantInterface,
    options?: MutateOptions<MutateResponse, unknown, PrivateQueryData<any>, unknown>
  ) => {
    return query.mutate(
      {
        data,
        token: token!,
      },
      options
    );
  };

  const status = useStatusProcessor(query);

  return {
    updateLubricant,
    ...query,
    ...status,
  };
};

export const useDeleteLubricant = () => {
  const token = useToken();

  const query = useMutation(DeleteLubricant, {
    onSuccess: () => {
      queryClient.invalidateQueries("PlantAux");
      queryClient.invalidateQueries("Lubricants");
    },
  });

  const deleteLubricant = (
    data: LubricantInterface,
    options?: MutateOptions<MutateResponse, unknown, PrivateQueryData<any>, unknown>
  ) => {
    return query.mutate(
      {
        data,
        token: token!,
      },
      options
    );
  };

  const status = useStatusProcessor(query);

  return {
    deleteLubricant,
    ...query,
    ...status,
  };
};

interface DeleteLubricantErrors {
  equipments?: EquipmentInterface[] | undefined | null;
  containers?: ContainersInterface[] | undefined | null;
}

export const useDeleteLubricantVerification = () => {
  const query = useDeleteLubricant();
  const { deleteLubricant } = query;
  const [status, setStatus] = useState<QueryStatus>("idle");
  const [validationElement, setValidationElement] = useState<LubricantInterface>();
  //validations
  const {
    data: equipmentsWithLubricants,
    status: equipmentsWithLubricantsStatus,
    remove: removeEquipmentsWithLubricants,
  } = useEquipmentsByLubricant(validationElement);
  const {
    data: containersWithLubricants,
    status: containersWithLubricantsStatus,
    remove: removeContainersWithLubricants,
  } = useContainersByLubricantTypeAndLubricant(validationElement);
  //errorStatus
  const [errors, setErrors] = useState<DeleteLubricantErrors>({});

  const removeQueries = () => {
    removeEquipmentsWithLubricants();
    removeContainersWithLubricants();
  };

  const validate = (item: LubricantInterface) => {
    setErrors({});
    removeQueries();
    setStatus("loading");
    setValidationElement(item);
  };

  const handleDelete = () => {
    if (
      equipmentsWithLubricantsStatus === "success" &&
      containersWithLubricantsStatus === "success"
    ) {
      if (equipmentsWithLubricants?.length > 0 || containersWithLubricants?.lenght > 0) {
        setErrors({
          equipments: equipmentsWithLubricants,
          containers: containersWithLubricants,
        });
        setStatus("error");
        setValidationElement(undefined);
      } else {
        if (!!validationElement) {
          deleteLubricant(validationElement, {
            onSuccess: () => {
              setValidationElement(undefined);
              setStatus("success");
            },
            onError: err => console.log(err),
          });
        }
      }
    }
    if (equipmentsWithLubricantsStatus === "loading") {
      setStatus("loading");
    }
  };

  useEffect(() => {
    validationElement && handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentsWithLubricantsStatus, containersWithLubricantsStatus]);

  return {
    errors,
    status,
    validate,
    query,
  };
};

export const useDailyLubricants = (lubricationPoints?: LubricationPointInterface[]) => {
  const { token, tagFP } = useSessionContext();
  /*     const lubricatorData = useOperatorDailyData(operator) */

  const points = useMemo(
    () =>
      lubricationPoints?.map(point => ({
        ...point,
        supplies: JSON.stringify(point.supplies),
        info: undefined,
      })),
    [lubricationPoints]
  );

  return useQuery({
    queryKey: "DailyOperatorLubricants",
    queryFn: () => getDailyLubricants({ data: points, tagFP, token }),
    enabled: !!points,
    staleTime: 1,
    /*  select:(data)=>data.map((l:LubricantInterface)=>{
            return {
                lubricant:l.lubricant,
                lubricationPoints: points?.filter((p)=>p.lubricant === l.lubricant.lubricant)!
            } 
        })  */
  });
};
