import { useMemo } from "react";
import { useDate } from "../../../shared/date/useDate";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { queryClient, useMutation, useQuery } from "../../../shared/react-query/react-query-conf";
import { ComponentInterface } from "../../components/models/ComponentInterface";
import { CriticalityInterface } from "../../criticalities/models/criticalityInterface";
import { ElementInterface } from "../../elements/models/ElementInterface";
import { useLeakLevel } from "../../infoLubricationPoints/components/hooks/leakLevel";
import { LubricantInterface } from "../../lubricant/model/LubricantInterface";
import { PermissionInterface } from "../../permisions/model/PermissionInterface";
import { useEquipmentsPermissionsByTagFP } from "../../permisions/services/service.permission";
import { SectorInterface } from "../../sectors/models/SectorInterface";
import { useSessionContext } from "../../session/store/sessionContext";
import { useToken } from "../../session/store/sessionStore";
import { SupplyInterface } from "../../supply/model/SupplyInterface";
import { TypeInterface } from "../../types/models/TypeInterface";
import {
  CreateEquipment,
  CreateLubricationPoint,
  DaoEquipmentsByTagFP,
  DaoEquipmentsElementsByTagFP,
  DayPendingLubricationPoints,
  DeleteEquipment,
  EquipmentsByComponent,
  EquipmentsByCriticality,
  EquipmentsByElement,
  EquipmentsByEquipmentType,
  EquipmentsByLubricant,
  EquipmentsByLubricantType,
  EquipmentsByMeasureUnit,
  EquipmentsByPermission,
  EquipmentsByRoute,
  EquipmentsBySectorName,
  EquipmentsBySupply,
  EquipmentVerification,
  GetAllLubricationPointsAndInfoByTagFP,
  GetEquipmentsbyTagFP,
  LubricationPointByTagTGD,
  UpdateLubricationPointNoImpact,
  UpdateEquipmentsState,
  UpdateHumanErrors,
  UpdateLubricationPoint,
  LubricationPointReviewsByTagTGDAndTagFP,
  LabelReplacementByRoute,
  EquipmentAttrValues,
  LubricationPointAndInfoByTagTGD,
} from "./lubricationPointsService";
import { equipmentType, LubricationPointInterface } from "../model/lubricationPointsInterface";
import { RouteInterface } from "../../routes/services/RoutesInterface";
import { getViewDateDay } from "../../../shared/date/utils";
import { escapeInnerQuotes } from "../utils/arrayStringUtil";

const suppliesAdapter = (supplies: any): LubricationPointInterface["supplies"] => {
  const escapedSupplies = escapeInnerQuotes(supplies);

  try {
    return JSON.parse(escapedSupplies || '["SIN INSUMOS"]');
  } catch (e) {
    console.error("Error parsing JSON:", e);
    return JSON.parse('["SIN INSUMOS"]');
  }
};

export const lubricationPointsByTagFPAdapter = (lubricationPoints: any) => {
  return lubricationPoints.map((point: any) => ({
    id: point.id,
    tagTGD: point.tagTGD,
    sector: point.sector,
    plantTag: point.plantTag,
    tagFP: point.tagFP,
    equipment: point.equipment,
    type: point.type,
    criticality: point.criticality,
    state: point.state,
    egressDate: point.egressDate,
    lowReason: point.lowReason,
    admissionDate: point.admissionDate,
    brand: point.brand,
    capacity: point.capacity,
    component: point.component,
    element: point.element,
    function: point.function,
    labelId: point.labelId,
    location: point.location,
    lubricant: point.lubricant,
    lubricantType: point.lubricantType,
    measureUnit: point.measureUnit,
    model: point.model,
    observations: point.observations,
    oilAnalysis: point.oilAnalysis,
    review: point.review,
    routes: point.routes,
    supplies: suppliesAdapter(point.info?.supplies),
    permissions: point.permissions,
    tagTGDBefore: point.tagTGDBefore,
    info: !point.info
      ? undefined
      : {
          id: point.info.id,
          tagTGD: point.info.tagTGD,
          supplies: suppliesAdapter(point.info?.supplies),
          labelReplacement: point.info.labelReplacement,
          lastLubrication: point.info.lastLubrication,
          lubricantQuantity: point.info.lubricantQuantity,
          leak: point.info.leak,
          pending: point.info.pending,
          updatedToday: point.info.updatedToday,
          user: point.info.user,
          lastSurveyId: point.info.lastSurveyId,
          assetCriticality: point.info.assetCriticality,
          inaccessible: point.info.inaccessible,
          tagFP: point.info.tagFP,
          lastInspectionDate: point.info.lastInspectionDate,
          updateReview: point.info.updateReview,
          temperature: point.info.temperature,
        },
    suggestedQuantity: point.suggestedQuantity || "",
  }));
};

//----------------------------
//QUERIES
//---------------------------------------------

export interface DayPending {
  date: "2022-05-03T00:00:00.000+00:00";
  id: number;
  pendingToday: string;
  previousPending: string;
  tagFP: string;
}

export const useDayPendingPointsByDate = () => {
  const { token, tagFP } = useSessionContext();
  const { nowDay, nowMonth, nowYear } = useDate();

  const data = useMemo(
    () => ({
      date: `${nowYear}-${nowMonth}-${nowDay}`,
      tagFP,
    }),
    [nowDay, nowMonth, nowYear, tagFP]
  );

  const routeInfoLubricationPointAdapter = (array: string[]) =>
    array.map((point: string) => ({
      tagTGD: point.split("|")[0],
      route: point.split("|")[1],
    }));

  return useQuery({
    queryKey: "dayPendingLubricationPoints",
    queryFn: () => DayPendingLubricationPoints({ data, token }),
    enabled: !!nowDay && !!tagFP,
    select: (dayPending: DayPending) => {
      return routeInfoLubricationPointAdapter(JSON.parse(dayPending.previousPending));
    },
  });
};

export const useLubricationPointsAndInfoByTagFP = () => {
  const { currentPlant, token } = useSessionContext();
  const tagFP = currentPlant?.tagFP;
  const result = useQuery<LubricationPointInterface[]>({
    queryKey: "LubricationPointsWithInfo",
    queryFn: () => GetAllLubricationPointsAndInfoByTagFP({ tagFP: tagFP!, token: token! }),
    select: lubricationPointsByTagFPAdapter,
    enabled: !!tagFP && !!token,
    staleTime: 8 * 60 * 60 * 1000,
  });

  return result;
};

export const useLubricationPointByTagTGD = (tagTGD?: string) => {
  const token = useToken();
  return useQuery({
    queryKey: "LubricationPointByTagTGD",
    queryFn: () => LubricationPointByTagTGD({ data: { tagTGD: tagTGD! }, token: token! }),
    staleTime: 0,
    enabled: !!tagTGD && !!token,
  });
};

export const useLubricationPointAndInfoByTagTGD = (tagTGD: string) => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["LubricationPointAndInfoByTagTGD", tagTGD],
    queryFn: () => LubricationPointAndInfoByTagTGD({ token, tagFP, tagTGD }),
    staleTime: 8 * 60 * 60 * 1000,
    select: item => {
      return lubricationPointsByTagFPAdapter([item])[0];
    },
    enabled: !!tagTGD && !!token,
  });
};

export const useLubricationPointReviewsByTagTGDAndTagFP = (tagTGD: string) => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: "LubricationPointReviewsByTagTGDAndTagFP",
    queryFn: () =>
      LubricationPointReviewsByTagTGDAndTagFP({
        tagTGD: tagTGD!,
        tagFP: tagFP!,
        token: token!,
      }),
    staleTime: 0,
    enabled: !!tagTGD && !!tagFP && !!token,
  });
};

export const useDaoEquipmentsByTagFP = () => {
  const { token, tagFP } = useSessionContext();
  return useQuery({
    queryKey: "DaoEquipmentsByTagFP",
    queryFn: () => DaoEquipmentsByTagFP({ tagFP: tagFP, token: token }),
    enabled: !!tagFP && !!token,
    select: data => {
      const reduced = data?.reduce((acc: any, item: any, _index: number) => {
        let shouldShow = false;

        item.lubricationPoints?.every((lubricationPoint: any) => {
          if (lubricationPoint.state) {
            shouldShow = true;
            return false; // Break the loop
          }

          return true;
        });

        if (shouldShow) {
          const newItem = {
            ...item,
            lubricationPoints: {},
            elements: {},
          };

          acc.push(newItem);
        }

        return acc;
      }, []);

      return reduced;
    },
  });
};

export const useDaoEquipmentsElementsByTagFP = () => {
  const { token, tagFP } = useSessionContext();
  return useQuery({
    queryKey: "DaoEquipmentsElementsByTagFP",
    queryFn: () => DaoEquipmentsElementsByTagFP({ tagFP: tagFP, token: token }),
    enabled: !!tagFP && !!token,
    select: data => {
      return data?.map((item: any, index: number) => ({ ...item, id: index }));
    },
  });
};

export const useEquipmentsByTagFP = () => {
  const { tagFP, token } = useSessionContext();

  const queryEquipments = useQuery<LubricationPointInterface[]>({
    queryKey: "EquipmentsByTagFP",
    queryFn: () => GetEquipmentsbyTagFP({ tagFP, token }),
    enabled: !!tagFP && !!token,
    staleTime: 10000,
  });

  const queryPermissions = useEquipmentsPermissionsByTagFP();

  const data: (LubricationPointInterface & { permissions: string[] })[] | undefined = useMemo(
    () =>
      queryEquipments.data?.map(equipment => ({
        ...equipment,
        permissions: queryPermissions.data
          ?.filter(permission => permission.equipment === equipment.tagTGD)
          .map(i => i.permissionName) || ["SIN PERMISOS"],
      })),
    [queryEquipments, queryPermissions.data]
  );

  return {
    ...queryEquipments,
    data,
  };
};

export const useLubricationPointsWithLeakLevel = (
  lubricationPoints: LubricationPointInterface[] | undefined
) => {
  const { calculateLeakLevel } = useLeakLevel();
  return useMemo(() => {
    return lubricationPoints?.map(point => ({
      ...point,
      leakLevel: calculateLeakLevel(point.info?.lubricantQuantity || 0, point.capacity),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lubricationPoints]);
};

export const useEquipmentsByLubricantType = (type: TypeInterface | {} | undefined) => {
  const token = useToken();
  return useQuery({
    queryKey: "EquipmentsByLubricantType",
    queryFn: () => EquipmentsByLubricantType({ data: type, token }),
    staleTime: 1,
    enabled: !!type,
  });
};

export const useEquipmentsByPermission = (permission: PermissionInterface | {} | undefined) => {
  const token = useToken();
  return useQuery({
    queryKey: "EquipmentsByPermission",
    queryFn: () => EquipmentsByPermission({ data: permission, token }),
    staleTime: 1,
    enabled: !!permission,
  });
};
export const useEquipmentsByEquipmentType = (type: TypeInterface | {} | undefined) => {
  const token = useToken();
  return useQuery({
    queryKey: "EquipmentsByEquipmentType",
    queryFn: () => EquipmentsByEquipmentType({ data: type, token }),
    staleTime: 1,
    enabled: !!type,
  });
};
export const useEquipmentsByMeassureUnit = (type: TypeInterface | {} | undefined) => {
  const token = useToken();
  return useQuery({
    queryKey: "EquipmentsByMeasureUnit",
    queryFn: () => EquipmentsByMeasureUnit({ data: type, token }),
    staleTime: 1,
    enabled: !!type,
  });
};

export const useEquipmentsByComponent = (component: ComponentInterface | {} | undefined) => {
  const token = useToken();
  return useQuery({
    queryKey: "EquipmentsByComponent",
    queryFn: () => EquipmentsByComponent({ data: component, token }),
    staleTime: 1,
    enabled: !!component,
  });
};

export const useEquipmentsByElement = (element: ElementInterface | {} | undefined) => {
  const token = useToken();
  return useQuery({
    queryKey: "EquipmentsByElement",
    queryFn: () => EquipmentsByElement({ data: element, token }),
    staleTime: 1,
    enabled: !!element,
  });
};

export const useEquipmentsBySectorName = (sector: SectorInterface | {} | undefined) => {
  const token = useToken();
  return useQuery({
    queryKey: "EquipmentsBySectorName",
    queryFn: () => EquipmentsBySectorName({ data: sector, token }),
    staleTime: 1,
    enabled: !!sector,
  });
};

export const useEquipmentsByLubricant = (lubricant: LubricantInterface | {} | undefined) => {
  const token = useToken();
  return useQuery({
    queryKey: "EquipmentsByLubricant",
    queryFn: () => EquipmentsByLubricant({ data: lubricant, token }),
    staleTime: 1,
    enabled: !!lubricant,
  });
};

export const useEquipmentsBySupply = (supply: SupplyInterface | {} | undefined) => {
  const token = useToken();
  return useQuery({
    queryKey: "EquipmentsBySupply",
    queryFn: () => EquipmentsBySupply({ data: supply, token }),
    enabled: !!token && !!supply,
  });
};

export const useEquipmentsByCriticality = (criticality: CriticalityInterface | {} | undefined) => {
  const token = useToken();
  return useQuery({
    queryKey: "EquipmentsByCriticality",
    queryFn: () => EquipmentsByCriticality({ data: criticality, token }),
    enabled: !!token && !!criticality,
  });
};

export const useEquipmentsByRoute = (route: RouteInterface | {} | undefined) => {
  const token = useToken();
  return useQuery({
    queryKey: "EquipmentsByRoute",
    queryFn: () => EquipmentsByRoute({ data: route, token }),
    enabled: !!token && !!route,
  });
};

//----------------------------
//USE Mutation
//---------------------------------------------

const EquipmentsDependencies = [
  "DaoEquipmentsByTagFP",
  "DaoEquipmentsElementsByTagFP",
  "EquipmentsByTagFP",
  "AllLubricationPointsAndInfoByTagFP",
  "EquipmentsByLubricantType",
  "EquipmentsByPermission",
  "EquipmentsByEquipmentType",
  "EquipmentsByMeasureUnit",
  "EquipmentsByComponent",
  "EquipmentsByElement",
  "EquipmentsBySectorName",
  "EquipmentsByLubricant",
  "EquipmentsBySupply",
  "EquipmentsByCriticality",
  "EquipmentsByRoute",
  "EquipmentsInfoByTagFP",
  "AllEquipmentsAndInfoByTagFP",
];

export const useUpdateEquipmentNextRevision = () => {
  const token = useToken();
  const query = useMutation(UpdateLubricationPoint, {
    onSuccess: () => {
      EquipmentsDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      });
    },
  });
  const status = useStatusProcessor(query);

  const updateEquipmentNextRevision = (data: LubricationPointInterface) => {
    /** este endpoint tine impacto en infoequipos tambien */
    return query.mutate({
      token,
      data: {
        tagFP: data.tagFP,
        brand: data.brand,
        model: data.model,
        element: data.element,
        component: data.component,
        review: `${parseInt(data.review) + 1}`,
        observations: data.observations,
        lubricantType: data.lubricantType,
        lubricant: data.lubricant,
        location: data.location,
        capacity: data.capacity,
        measureUnit: data.measureUnit,
        oilAnalysis: data.oilAnalysis,
        tagTGD: data.tagTGD,
        function: data.function,
      },
    });
  };

  return {
    updateEquipmentNextRevision,
    ...query,
    ...status,
  };
};

export const useEquipmentVerification = () => {
  return useMutation(EquipmentVerification);
};

export const useCreateEquipment = () => {
  return useMutation(CreateEquipment, {
    onSuccess: () => {
      EquipmentsDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      });
    },
  });
};

export const useCreateLubricationPoint = () => {
  const token = useToken();
  const query = useMutation(CreateLubricationPoint, {
    onSuccess: () => {
      EquipmentsDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      });
    },
  });

  const createLubricationPoint = (data: {
    tagTGD: string;
    tagFP: string;
    sector: string;
    plantTag: string;
    equipment: string;
    type: string;
    criticality: string;
    brand: string;
    model: string;
    capacity: number;
    component: string;
    element: string;
    function: string;
    location: string;
    lubricant: string;
    lubricantType: string;
    measureUnit: string;
    observations: string;
    oilAnalysis: boolean;
    review: number;
    suggestedQuantity: LubricationPointInterface["suggestedQuantity"];
  }) => {
    return query.mutate({
      token,
      data,
    });
  };

  const status = useStatusProcessor(query);

  return {
    createLubricationPoint,
    ...query,
    ...status,
  };
};

export const useUpdateEquipmentNoImpact = () => {
  return useMutation(UpdateLubricationPointNoImpact, {
    onSuccess: () => {
      EquipmentsDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      });
    },
  });
};

export const useUpdateLubricationPoint = () => {
  return useMutation(UpdateLubricationPoint, {
    onSuccess: () => {
      EquipmentsDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      });
    },
  });
};
export const useUpdateHumanErrors = () => {
  return useMutation(UpdateHumanErrors, {
    onSuccess: () => {
      EquipmentsDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      });
    },
  });
};

export const useDeleteEquipment = () => {
  return useMutation(DeleteEquipment, {
    onSuccess: () => {
      EquipmentsDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      });
    },
  });
};

export const useUpdateLubricationPointState = () => {
  const token = useToken();
  const query = useMutation(UpdateEquipmentsState, {
    onSuccess: () => {
      EquipmentsDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      });
    },
  });

  interface UpdateLubricationPointsInterface {
    tagFP?: string;
    lowReason: string;
    tagTGD: string;
  }

  const activateLubricationPoint = ({ lowReason, tagTGD }: UpdateLubricationPointsInterface) => {
    return query.mutate({
      token,
      data: {
        lowReason,
        tagTGD,
        state: true,
      },
    });
  };

  const desactivateLubricationPoint = ({ lowReason, tagTGD }: UpdateLubricationPointsInterface) => {
    return query.mutate({
      token,
      data: {
        lowReason,
        tagTGD,
        state: false,
      },
    });
  };

  return {
    activateLubricationPoint,
    desactivateLubricationPoint,
    queryData: query,
  };
};

export const lubricationPointVerificationArray = (
  points: any[],
  element: string,
  component: string
) => {
  var iterators: { [key: string]: string } = {};

  points.forEach((i: any) => {
    iterators[i.element] = i.component;
  });

  return iterators[element] === component ? "ya existe" : undefined;
};

export const useEquipmentsTag = (point?: any) => {
  const getTag = (item: any, type: equipmentType) => {
    const tags = {
      equipment: `${item?.tagTGD.split("-")[0]}`,
      element: `${item?.tagTGD.split("-")[0]}-${item?.tagTGD.split("-")[1]}`,
      component: `${item?.tagTGD}`,
    };
    return tags[type];
  };

  switch (!!point) {
    case true:
      return {
        getTag,
        equipmentTag: getTag(point, equipmentType.equipment),
        elementTag: getTag(point, equipmentType.element),
        componentTag: getTag(point, equipmentType.component),
      };
    default:
      return {
        getTag,
      };
  }
};

export const useProcesedEquipmentsByTagFPRefactor = () => {
  const {
    data: lubricationPoints,
    status: lubricationPointsStatus,
    error: lubricationPointsError,
    isFetching: isFetchinglubricationPoints,
  } = useEquipmentsByTagFP();
  const { data: equipments } = useDaoEquipmentsByTagFP();
  const { data: elements } = useDaoEquipmentsElementsByTagFP();

  return {
    equipments,
    elements,
    lubricationPoints,
    lubricationPointsStatus,
    lubricationPointsError,
    isFetchinglubricationPoints,
  };
};

export const useEquipmentswithCompleteInfo = (
  select?: (data: LubricationPointInterface[]) => LubricantInterface[]
) => {
  const { data: equipments } = useLubricationPointsAndInfoByTagFP();

  const lubricationPointsquery = useLubricationPointsWithLeakLevel(equipments);

  const dataComposition = (i: LubricationPointInterface) => ({
    //fix interface from this source of data
    ...i,
    admissionDate: getViewDateDay(i.admissionDate),
    egressDate: getViewDateDay(i.egressDate),
    info: {
      ...i.info,
      lastInspectionDate: getViewDateDay(i.info?.lastInspectionDate),
      lastLubrication: getViewDateDay(i.info?.lastLubrication),
    },
  });

  const result = useMemo(
    () => lubricationPointsquery?.map(dataComposition),
    [lubricationPointsquery]
  );

  return select && result ? select(result as any) : result;
};

export const useLabelReplacementByRoute = (route: string) => {
  const { token, tagFP } = useSessionContext();
  const data = { route };

  return useQuery<LubricationPointInterface[]>({
    queryKey: ["LabelReplacementByRoute", route],
    queryFn: () => LabelReplacementByRoute({ tagFP, token, data }),
    enabled: !!tagFP,
    staleTime: 60000,
  });
};

export const useEquipmentAttrValues = () => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["EquipmentAttrValues"],
    queryFn: () => EquipmentAttrValues({ tagFP, token }),
    enabled: !!tagFP,
    staleTime: 3600000,
    cacheTime: Infinity,
  });
};
